import React, { Component } from "react";
import classNames from "classnames/bind";
import style from "./promotions.module.scss";
import { promociones1, promociones2 } from "../../../../assets/images";
import { Gallery, LineSeparator } from "../../../../components";
const cx = classNames.bind(style);

class Promotions extends Component {
  render() {
    const images = [
      {
        image: promociones2,
        contentOrientation: "left", // center, right
        alignText: "center",
        textContent: this._textContent(),
      },
      {
        image: promociones1,
        contentOrientation: "left", // center, right
        alignText: "center",
        textContent: this._textContent(),
      },
    ];
    return (
      <div name={"promotions"}>
        <Gallery images={images} />
        <LineSeparator />
      </div>
    );
  }
  _textContent = () => (
    <div className={cx("text-promotions")}>
      <h1>
        En tu
        <br />
        celebración
        <br />
        queremos estar
      </h1>
      <p>Cajitas Sorpresas</p>
    </div>
  );
}

export default Promotions;
