import React, { Component } from "react";
import {
  GalleryProducts,
  Products,
  Promotions,
  RequestFood,
  ContactUs,
} from "./components";
import { scroller } from "react-scroll";

const scrollType = {
  duration: 2000,
  delay: 550,
  smooth: true, // linear “easeInQuint” “easeOutCubic”
};

class PrincipalPage extends Component {
  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id === "pdf")
      scroller.scrollTo("request-food", scrollType);
  }
  render() {
    return (
      <>
        <GalleryProducts />
        <Products />
        <Promotions />
        <RequestFood />
        <ContactUs />
      </>
    );
  }
}

export default PrincipalPage;
