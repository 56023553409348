export const registerUser = (dispatch, user) => {
  dispatch({
    type: 'REGISTER_USER',
    payload: user
  });
}

export const registerUserLvl = (user) => {
  return {
    type: 'REGISTER_USER',
    payload: user,
  };
};
