// import data from 'assets/json/products.json';
import menu from 'assets/json/menu.json';

const initialState = {
  shopping: {
    promotions: [],
    products: [],
    totalShopping: 0,
    totalCountProducts: 0
  },
  products: menu,
  user: null
};

export default initialState;
