import React from 'react';
import { useStateValue } from 'redux/store';
import { Modal } from 'components';
import { deleteProduct } from 'redux/reducers/shopping/actions';
import { capitalizeFirstLetter, formatterPeso } from 'helpers';
import { remove, products as imgs } from 'assets/images';
import style from './modal_shopping_products.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

function ModalShoppingProducts({ showModal, closeModal }) {
  const [
    {
      shopping,
      shopping: { totalCountProducts, totalShopping },
    },
    dispatch,
  ] = useStateValue();

  const renderPriceProduct = (precio, ingredientes) => {
    let precioProducto = precio;
    ingredientes.forEach(
      (ingrediente) => (precioProducto += ingrediente.value)
    );
    return (
      <p className={cx('price')}>{formatterPeso.format(precioProducto)}</p>
    );
  };

  const renderIngredients = (ingredients) => {
    var text = '';
    ingredients.forEach((ingrediente) => {
      ingrediente.descripcion !== 'No'
        ? ingrediente.descripcion === 'Si'
          ? (text += ` · ${ingrediente.titulo}`)
          : (text += ` · ${ingrediente.descripcion}`)
        : (text += '');
    });
    return (
      <p>
        {text.length >= 25
          ? text.substr(0, 25).replace(/\W*\s(\S)*$/, '...')
          : text}
      </p>
    );
  };

  const productsShopping = (productsTypes) => {
    return shopping[productsTypes].map((item, key) => (
      <div key={key} className={cx('shopping-card')}>
        <div className={cx('img-product')}>
          <img
            src={
              imgs[item.categoria.toLowerCase().trim().replace(' ', '')][
                productsTypes
              ][item.imagen]
            }
            alt='Producto'
          />
        </div>
        <div className={cx('content')}>
          <div className={cx('description')}>
            <h6>{`${item.nombre} ${item.combo ? 'en combo' : ''}`}</h6>
            <p style={{ textDecoration: 'underline' }}>
              {capitalizeFirstLetter(item.categoria).substring(
                0,
                item.categoria.length - 1
              )}
            </p>

            {renderIngredients(item.ingredientes)}
            {renderPriceProduct(
              item.precio + (item.combo ? item.combo : 0),
              item.ingredientes
            )}
          </div>
          <div
            className={cx('icon-remove-product')}
            onClick={() =>
              deleteProduct(
                dispatch,
                key,
                shopping[productsTypes],
                productsTypes === 'promotions' && true
              )
            }
          >
            <img src={remove} width='25' alt='Eliminar producto' />
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Modal
      showModal={showModal}
      handleClickClose={() => closeModal()}
      handleClickSubmit={() => closeModal(true)}
      showBottomAccept={totalCountProducts > 0}
      bottomAcceptDisabled={false}
      buttonTextSubmit='Solicitar Pedido'
    >
      {totalCountProducts > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div
            style={{
              marginLeft: '30px',
              marginRight: '30px',
              marginBottom: '20px',
            }}
          >
            Hola, sí va tu <b>pedido:</b>
          </div>
          <div style={{ flexGrow: 1, overflowY: 'scroll' }}>
            {productsShopping('products')}
          </div>
          <div
            style={{
              marginTop: '20px',
              padding: '0px 30px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <p style={{ fontSize: '18px', marginBottom: 0 }}>
              <b>Total:</b>&nbsp;
            </p>
            <p style={{ fontSize: '18px', marginBottom: 0 }}>
              {formatterPeso.format(totalShopping)}
            </p>
          </div>
        </div>
      ) : (
        <div className={cx('container-message')}>
          <p>Selecciona los productos que deseas comprar</p>
        </div>
      )}
    </Modal>
  );
}

export default ModalShoppingProducts;
