import React, { Component } from "react";
import classNames from "classnames/bind";
import style from "./gallery.module.scss";
import PropTypes from "prop-types";
import {
  MDBMask,
  MDBView,
  MDBCarouselItem,
  MDBCarousel,
  MDBCarouselInner,
} from "mdbreact";

const cx = classNames.bind(style);

class Gallery extends Component {
  static defaultProps = {
    images: [],
  };

  _imageGallery = (images) =>
    images.map((item, index) => (
      <MDBCarouselItem key={`key-${index}`} itemId={index + 1}>
        <MDBView>
          <img
            className={cx("heightImgCarousel")}
            src={item.image}
            alt={`${index + 1} slide`}
          />
          <MDBMask overlay="black-slight" />
        </MDBView>
        <div className={cx("content-img", item.contentOrientation)}>
          <div
            className={cx(
              "container-text",
              item.contentOrientation,
              `text-${item.alignText}`
            )}
          >
            {item.textContent}
          </div>
        </div>
      </MDBCarouselItem>
    ));

  render() {
    return (
      <MDBView>
        <MDBCarousel
          activeItem={1}
          length={this.props.images.length}
          showControls={true}
          showIndicators={true}
          className="z-depth-1"
        >
          <MDBCarouselInner>
            {this._imageGallery(this.props.images)}
          </MDBCarouselInner>
        </MDBCarousel>
      </MDBView>
    );
  }
}

Gallery.propTypes = {
  images: PropTypes.array,
};

export default Gallery;
