import React, { Component } from "react";

import {
  carouselImg1,
  carouselImg2,
  carouselImg3,
  carouselImg4,
  carouselImg5,
} from "../../../../assets/images";

import classNames from "classnames/bind";
import { Gallery } from "../../../../components";
import style from "./gallery_products.module.scss";

const cx = classNames.bind(style);

class GalleryProducts extends Component {
  render() {
    const images = [
      {
        image: carouselImg5,
        contentOrientation: "right", // center, right
        alignText: "bottom", // center, bottom
        textContent: (
          <div className={cx("text-restaurant")}>
            <p>
              Nuevamente
              <span>¡BIENVENIDOS!</span>
              Haz tu Reserva
            </p>
          </div>
        ),
      },
      {
        image: carouselImg1,
        contentOrientation: "left", // center, right
        alignText: "center", // center, bottom
        textContent: (
          <div className={cx("text-arepa")}>
            Pide a Domicilio
            <h1>Rellena tu día de Sabor</h1>
            <span>
              Mucho más que una arepa, es lo que nos identifica de nuestros
              sabores agradables y frescos para disfrutar de inmediato.
            </span>
          </div>
        ),
      },
      {
        image: carouselImg2,
        contentOrientation: "right", // center, right
        alignText: "center", // center, bottom
        textContent: (
          <div className={cx("text-domicile")}>
            <h3>
              Domicilios
              <br />
              <span>Sujeto a cobertura</span>
              ó<br />
              Para llevar
            </h3>
          </div>
        ),
      },
      {
        image: carouselImg3,
        contentOrientation: "right", // center, right
        alignText: "bottom", // center, bottom
        textContent: (
          <div className={cx("text-hamburger")}>
            <h3>
              Hamburguesa
              <br /> de Erepa
            </h3>
            <p>
              Disfruta una de las mejores hamburguesas de la casa, está hecha de
              carne de Res, Carne de cerdo desmechada (Hecha al horno por mas de
              7 horas), Tocineta, Vegetales Salteados con salsa teriyaki,
              pimenton y cebolla.
            </p>
          </div>
        ),
      },
      {
        image: carouselImg4,
        contentOrientation: "left", // center, right
        alignText: "top", // center, bottom
        textContent: (
          <div className={cx("text-new-line-products")}>
            <p>NUEVA LÍNEA DE CONGELADOS</p>
            <h4>
              Hazlo en Casa
              <br />
              <span>
                &nbsp;Cobertura en todo <strong>BOGOTÁ</strong>&nbsp;
              </span>
            </h4>
          </div>
        ),
      },
    ];
    return <Gallery name={"gallery-products"} images={images} />;
  }
}

export default GalleryProducts;
