import React from "react";
import classNames from "classnames/bind";
import style from "./badge.module.scss";
const cx = classNames.bind(style);

function Badge({ value, top, right }) {
  return value ? (
    <span className={cx("badge")} style={{ top: top, right: right }}>
      {value}
    </span>
  ) : null;
}

export default Badge;
