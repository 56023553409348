import React from 'react';
import PropTypes from 'prop-types';
// import { lineClose } from "assets/images";
import { ButtonBack, ButtonPrincipal } from 'components';
import style from './modal.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

function Modal({
  showModal,
  handleClickClose,
  handleClickSubmit,
  bottomAcceptDisabled,
  showBottomAccept,
  showFooter,
  buttonTextSubmit,
  children,
}) {
  return (
    <div className={cx('modal', showModal ? 'show-in' : 'show-out')}>
      <div className={cx('modal-header')}>
        {/* <img src={lineClose} alt="close" width="20" /> */}
      </div>
      <div className={cx('modal-container')}>
        <div className={cx('modal-body')}>{children}</div>
        {showFooter && (
          <div className={cx('modal-footer')}>
            <ButtonBack onClick={handleClickClose} />
            {showBottomAccept && (
              <ButtonPrincipal
                disabled={bottomAcceptDisabled}
                onClick={!bottomAcceptDisabled ? handleClickSubmit : () => {}}
                text={buttonTextSubmit}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

Modal.propTypes = {
  bottomAcceptDisabled: PropTypes.bool,
  showBottomAccept: PropTypes.bool,
  showFooter: PropTypes.bool,
};

Modal.defaultProps = {
  bottomAcceptDisabled: true,
  showBottomAccept: true,
  showFooter: true,
  buttonTextSubmit: 'Siguiente',
};

export default Modal;
