import React, { useState, useEffect } from 'react';
import { useStateValue } from 'redux/store';
import { addProduct } from 'redux/reducers/shopping/actions';
import { Modal } from 'components';
import { formatterPeso } from 'helpers';
import { products as imgs } from 'assets/images';
import style from './modal_product_detail.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);
const OPTIONS_COMBO = {
  key: 'combo',
  options: [
    {
      descripcion: '¿Deseas pedirla en combo?',
      caracteristicas: [
        {
          caracteristica: 'No',
          precio: 0,
        },
        {
          caracteristica: 'Si',
          precio: 9800,
        },
      ],
    },
  ],
};

function ModalProductDetail({ selectedItem, clearSelectedItem }) {
  const [{ shopping }, dispatch] = useStateValue();
  const [radioButton, setRadioButton] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [selectedCombo, setSelectedCombo] = useState();

  const onSelectedProduct = () => {
    const { products } = shopping;
    addProduct(
      dispatch,
      {
        count: 1,
        categoria: selectedItem.category,
        ...selectedItem.productSelected,
        ingredientes: radioButton,
        combo: selectedCombo,
      },
      products
    );
    clearSelectedItem();
    setRadioButton([]);
    setSelectedCombo(null);
    setDisabledButton(false);
  };

  const addIngredientsProduct = (ingredient, item) => {
    const {
      productSelected: { detalleProducto },
    } = selectedItem;
    let indexItem = radioButton.findIndex(
      (item) => item.titulo === ingredient.key
    );
    if (indexItem >= 0) {
      radioButton[indexItem] = {
        titulo: ingredient.key,
        descripcion: item.caracteristica,
        value: item.precio,
      };
    } else {
      radioButton.push({
        titulo: ingredient.key,
        descripcion: item.caracteristica,
        value: item.precio,
      });
    }
    setRadioButton(radioButton);
    setDisabledButton(
      selectedItem.optionsCombo.length +
        selectedItem.ingredients.length +
        (detalleProducto?.length || 0) ===
        radioButton.length
    );
  };

  // const addAditionalProduct = (aditional) => {
  //   let indexItem = addAditional.findIndex(
  //     (item) => item.nombre === aditional.nombre
  //   );

  //   if (indexItem >= 0) {
  //     addAditional.splice(indexItem, 1);
  //   } else {
  //     addAditional.push(aditional);
  //   }

  //   setAddAditional(addAditional);
  // };

  // const listAditionals = (products, title, addProduct) => {
  //   return (
  //     <div className={cx('box-ingredients')}>
  //       <div key={`ingredient`}>
  //         <div key={`title-ingredients`} className={cx('section-ingredients')}>
  //           <div className={cx('box-padding')}>
  //             <p>
  //               {title}
  //               <br />
  //               <span>Selecciona las opciones que deseas</span>
  //             </p>
  //           </div>
  //         </div>
  //         {products.map((aditional, key) => (
  //           <div key={`caracteristic-${key}`} className={cx('section-items')}>
  //             <div className={cx('box-padding')}>
  //               <div>
  //                 <p className={cx('description')}>{aditional.nombre}</p>
  //                 {aditional.precio > 0 && (
  //                   <p className={cx('price')}>{`+${formatterPeso.format(
  //                     aditional.precio
  //                   )}`}</p>
  //                 )}
  //               </div>
  //               <div>
  //                 <input
  //                   type='checkbox'
  //                   value={aditional.precio}
  //                   name={`${aditional.nombre}${key}`}
  //                   onClick={() => addProduct(aditional)}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  const sectionOptions = (nameProduct, options, onChange) => {
    return (
      options.length &&
      options.map((option, index) => (
        <div key={`ingredient-${index}`}>
          <div
            key={`title-ingredients-${index}`}
            className={cx('section-ingredients')}
          >
            <div className={cx('box-padding')}>
              <p>
                {option.descripcion}
                <br />
                <span>Escoge 1 opción.</span>
              </p>
            </div>
          </div>
          {option.caracteristicas.map((item, key) => (
            <div key={`caracteristic-${key}`} className={cx('section-items')}>
              <div className={cx('box-padding')}>
                <div>
                  <p className={cx('description')}>{item.caracteristica}</p>
                  {item.precio > 0 && (
                    <p className={cx('price')}>{`+${formatterPeso.format(
                      item.precio
                    )}`}</p>
                  )}
                </div>
                <div>
                  <input
                    type='radio'
                    value={item.precio}
                    name={`${nameProduct}${index}`}
                    onChange={() => onChange(option, item)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ))
    );
  };

  const contentModalDetailProduct = (_) => {
    const { productSelected, ingredients, category, optionsCombo } =
      selectedItem;
    const showCombo =
      category === 'Arepas' ||
      category === 'Hamburguesas' ||
      category === 'Perros'
        ? true
        : false;
    return (
      <div className={cx('content-modal-selected-product')}>
        <div className={cx('header-product')}>
          <div className={cx('box-img')}>
            <img
              src={
                imgs[category.toLowerCase().trim().replace(' ', '')][
                  'products'
                ][productSelected.imagen]
              }
              alt='Producto'
            />
          </div>
          <div>
            <div className={cx('box-padding')}>
              <div className={cx('title')}>
                <p>{productSelected.nombre}</p>
                <p>{formatterPeso.format(productSelected.precio)}</p>
              </div>
              <div>
                <p style={{ fontSize: '0.8rem' }}>
                  {productSelected.descripcion}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('box-ingredients')}>
          {/* Ingredientes */}
          {ingredients.length > 0 &&
            sectionOptions(
              `${productSelected.nombre}-ingredients`,
              ingredients,
              addIngredientsProduct
            )}
          {productSelected?.detalleProducto?.length > 0 &&
            sectionOptions(
              `${productSelected.nombre}-detail`,
              productSelected.detalleProducto,
              addIngredientsProduct
            )}
          {/* Combo */}
          {showCombo &&
            sectionOptions(
              `${OPTIONS_COMBO.key}-combo`,
              OPTIONS_COMBO.options,
              (option, itemSelected) => {
                setSelectedCombo(
                  itemSelected.caracteristica === 'Si'
                    ? itemSelected.precio
                    : null
                );
                setDisabledButton(itemSelected.caracteristica !== 'Si');
              }
            )}
          {/* Options Combo */}
          {selectedCombo &&
            optionsCombo.length > 0 &&
            sectionOptions(
              `${productSelected.nombre}-optionsCombo`,
              optionsCombo,
              addIngredientsProduct
            )}
        </div>
        {/* {adicionales.length > 0 && showAditional &&
          listAditionals(adicionales, "Elige tus adiciones", (product) => addAditionalProduct(product))} */}
      </div>
    );
  };

  useEffect(() => {
    if (
      selectedItem &&
      !selectedItem?.optionsCombo?.length &&
      !selectedItem?.ingredients?.length &&
      !selectedItem?.productSelected?.detalleProducto?.length &&
      !disabledButton
    ) {
      setDisabledButton(true);
    }
  }, [selectedItem, disabledButton]);

  return (
    <Modal
      showModal={selectedItem != null}
      handleClickClose={() => {
        setRadioButton([]);
        clearSelectedItem();
        setSelectedCombo();
        setDisabledButton(false);
      }}
      handleClickSubmit={() => onSelectedProduct()}
      bottomAcceptDisabled={!disabledButton}
      buttonTextSubmit='Agregar'
    >
      {selectedItem != null && contentModalDetailProduct()}
    </Modal>
  );
}

export default ModalProductDetail;
