import React, { useState } from 'react';
// import { products as imgs, remove } from "assets/images";
import { useStateValue } from 'redux/store';
import { LineSeparator, Tabs, ButtonShopping } from 'components';
import {
  ModalShoppingProducts,
  ModalProductDetail,
  ModalContactUser,
} from './components';

import style from './e_commerce.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

function Ecommerce() {
  const [
    {
      products,
      shopping: { totalCountProducts },
    },
  ] = useStateValue();
  const [showModal, setShowModal] = useState(null);
  const [showModalContact, setShowModalContact] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <div className={cx('container')}>
      <div className={cx('gallery')}></div>
      <LineSeparator />
      <Tabs
        tabsContent={products}
        onSelectedItem={(item) => {
          setShowModal(false);
          setSelectedItem(item);
        }}
      />
      <ButtonShopping
        counterBadge={totalCountProducts}
        onClick={() => {
          setShowModal(true);
          setSelectedItem(null);
        }}
      />
      <ModalProductDetail
        selectedItem={selectedItem}
        clearSelectedItem={() => setSelectedItem(null)}
      />
      <ModalShoppingProducts
        showModal={showModal}
        closeModal={(productsSelected) => {
          setShowModal(!showModal);
          if (productsSelected) {
            setShowModalContact(!showModalContact);
          }
        }}
      />
      <ModalContactUser
        showModal={showModalContact}
        closeModal={(backShopping) => {
          setShowModalContact(!showModalContact);
          if (backShopping) {
            setShowModal(!showModal);
          }
        }}
      />
    </div>
  );
}

export default Ecommerce;
