import React, { Component } from 'react';
import classNames from 'classnames/bind';
import style from './contact_us.module.scss';
import { MDBIcon } from 'mdbreact';
import { phone, ubication, email, reloj } from '../../../../assets/images';
import { GoogleMaps } from '..';
// import { animateScroll as scroll } from "react-scroll";
const cx = classNames.bind(style);

class ContactUs extends Component {
  state = {
    showMaps: false,
  };

  render() {
    return (
      <div name={'contact'}>
        <div className={cx('content')}>
          <div className={cx('card-register')}>
            <h4 style={{ letterSpacing: 3 }}>VISÍTANOS</h4>
            <p>
              {
                'Para mantenerte informado de nuestras promociones,\ndescuento, ventas, ofertas especiales y más.'
              }
            </p>
            {/* <input
            type="text"
            className={cx("css-input")}
            placeholder="Ingresa tu correo"
          /> */}
            <div className={cx('box-red-social')}>
              <a
                href='https://www.facebook.com/erepa2013/'
                target='_blank'
                rel='noopener noreferrer'
                className='nav-link Ripple-parent waves-effect waves-light'
              >
                <MDBIcon fab icon='facebook' />
              </a>
              <a
                href='https://www.instagram.com/erepa_/'
                target='_blank'
                rel='noopener noreferrer'
                className='nav-link Ripple-parent waves-effect waves-light'
              >
                <MDBIcon fab icon='instagram' />
              </a>
              <a
                href='https://twitter.com/ereparrillacafe?lang=es'
                target='_blank'
                rel='noopener noreferrer'
                className='nav-link Ripple-parent waves-effect waves-light'
              >
                <MDBIcon fab icon='twitter' />
              </a>
              <a
                href='https://www.youtube.com/channel/UCpHZwd33j9kQb1dLzKeRuVg'
                target='_blank'
                rel='noopener noreferrer'
                className='nav-link Ripple-parent waves-effect waves-light'
              >
                <MDBIcon fab icon='youtube' />
              </a>
            </div>
          </div>
          <div className={cx('card-contact')}>
            <h4 style={{ letterSpacing: 3 }}>CONTÁCTANOS</h4>
            <div className={cx('card-container')}>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  position: 'relative',
                }}
                onClick={() => {
                  // this.setState({ showMaps: true });
                  // scroll.scrollToBottom();
                  window.open(
                    'https://www.google.com/maps/place/Cl.+8+Sur+%2338B-04,+Bogot%C3%A1/@4.6049557,-74.1164543,17z/data=!3m1!4b1!4m6!3m5!1s0x8e3f99370b7b23bb:0x25e92c4b2bf8d44a!8m2!3d4.6049557!4d-74.1138794!16s%2Fg%2F11j3jdl9y7?entry=ttu',
                    '_blank'
                  );
                }}
              >
                <img src={ubication} alt='Estrella' width='25' height='25' />
                <p>{'Calle 8 Sur # 38b 04, Ciudad Montes, Bogotá'}</p>
              </span>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  position: 'relative',
                }}
                onClick={() => {
                  window.open(
                    'https://wa.me/573212516302?text=¡Hola!%20Buen%20día,%20*quisiera%20hacer%20un%20pedido.*',
                    '_blank'
                  );
                }}
              >
                <img src={phone} alt='Estrella' width='25' height='25' />
                <p>{'+57 (1) 321 251 6302'}</p>
              </span>
              <span style={{ display: 'flex', flexDirection: 'row' }}>
                <img src={email} alt='Estrella' width='25' height='25' />
                <p>{'erepadesde2013@erepa.co'}</p>
              </span>
              <span style={{ display: 'flex', flexDirection: 'row' }}>
                <img src={reloj} alt='Estrella' width='25' height='25' />
                <p>
                  {'Lunes - Jueves 12:00pm - 10:00pm'}
                  <br />
                  {'Viernes 12:00pm - 11:00pm'}
                  <br />
                  {'Sabados 1:00pm - 11:00pm'}
                  <br />
                  {'Domingos y Festivos 1:00pm - 9:00pm'}
                </p>
              </span>
            </div>
          </div>
        </div>
        {this.state.showMaps && (
          <GoogleMaps
            ref={(input) => {
              this.nameInput = input;
            }}
            defaultValue='will focus'
          />
        )}
      </div>
    );
  }
}

export default ContactUs;
