import React, { createContext, useReducer, useContext } from 'react';
import logger from 'use-reducer-logger';

const AppContext = createContext();

export const Provider = ({ reducer, initialState, children }) => (
  <AppContext.Provider
    value={useReducer(
      process.env.NODE_ENV === "development" ? logger(reducer) : reducer,
      initialState
    )}
  >
  {children}
  </AppContext.Provider>
)

export const useStateValue = () => useContext(AppContext);