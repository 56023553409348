export const addProduct = (dispatch, newProduct, products, promotion) => {
  var type = promotion ? 'PRODUCT_PROMOTION' : 'PRODUCT';
  products.push(newProduct);
  var totalShopping = 0; 
  products.forEach(product => {
    totalShopping += countTotalProduct(product);
  });
  
  dispatch({
    type: type,
    payload: {
      products,
      totalShopping 
    }
  });
}

export const deleteProduct = (dispatch, indexProductUpdate, products, promotion) => {
  var type = promotion ? 'DELETE_PROMOTION' : 'DELETE';
  let subtractProduct = 0;
  if(products[indexProductUpdate] !== undefined) {
    subtractProduct = countTotalProduct(products[indexProductUpdate]);
    products.splice(indexProductUpdate, 1);
    dispatch({
      type: type,
      payload: {
        products,
        subtractProduct }
    });
  }
 
}

const countTotalProduct = (product) => {
  const { ingredientes, combo, precio } = product;
  let totalProduct = 0;
  // Combo
  if (combo) totalProduct += combo
  
  ingredientes.forEach(ingrediente => {
    totalProduct += ingrediente.value;
  })
  
  return totalProduct += precio;
}