import React, { Component } from "react";
import "./page_construction.scss";

class PageConstruction extends Component {
  render() {
    return <div id="pageConstruction"></div>;
  }
}

export default PageConstruction;
