import React, { useEffect, useRef } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavLink,
  MDBContainer,
  MDBIcon,
  MDBNavItem,
} from "mdbreact";
import { animateScroll as scroll } from "react-scroll";
import { logoErepaWhite } from "../../assets/images";
import ReactTooltip from "react-tooltip";
import classNames from "classnames/bind";
import style from "./navbar.module.scss";

const cx = classNames.bind(style);

const NavbarPage = () => {
  var refFoo = useRef();

  useEffect(() => {
    setTimeout(() => ReactTooltip.show(refFoo), 1000);
  });

  useEffect(() => {
    return () => {
      document.querySelector("nav").style.height = "auto";
    };
  });

  return (
    <div id="contactformpage" className={cx("contactformpage")}>
      <MDBNavbar
        id="navbar"
        color="brown darken-3"
        dark
        expand="md"
        fixed="top"
        scrolling
        transparent
      >
        <MDBContainer>
          <MDBNavbarNav style={{ flexDirection: "row", alignItems: "center" }}>
            <MDBNavItem>
              <MDBNavLink to="/">
                <img
                  className={cx("imageErepa")}
                  src={logoErepaWhite}
                  alt="Erepa"
                />
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem style={{ width: "50%" }}>
              <div className={cx("contentLinkProducts")}>
                <MDBNavLink
                  to=""
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    (window.location.href = "https://erepa.getjusto.com/pedir")
                  }
                >
                  <span className={cx("animationTitle")}>
                    Haz tu pedido aquí
                  </span>
                </MDBNavLink>
              </div>
            </MDBNavItem>
            <MDBNavItem>
              <div style={{ display: "flex" }}>
                <span
                  className="nav-link Ripple-parent waves-effect waves-light"
                  onClick={() => {
                    scroll.scrollToBottom();
                  }}
                >
                  <MDBIcon icon="map-marker-alt" />
                </span>
                <a
                  href="https://wa.me/573212516302?text=¡Hola!%0ABuen%20día,%20*quisiera%20hacer%20un%20pedido.*"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link Ripple-parent waves-effect waves-light"
                >
                  <MDBIcon fab icon="whatsapp" />
                </a>
              </div>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
};

export default NavbarPage;
