import React, { Component } from "react";
import classNames from "classnames/bind";
import style from "./request_food.module.scss";
import menuErepaPdf from "../../../../assets/docs/menuerepa.pdf";

const cx = classNames.bind(style);

class RequestFood extends Component {
  render() {
    return (
      <div name="request-food" className={cx("container")}>
        <div className={cx("content")}>
          {this.boxIcon([
            { description: "DESCARGAR\nMENÚ", url: menuErepaPdf },
          ])}
          <div className={cx("img-content")}>
            {this.boxIcon(
              [
                {
                  description: "PIDE EN LINEA",
                  url: "https://erepa.getjusto.com/pedir",
                },
              ],
              true
            )}
            {this.boxIcon([
              {
                description: "IFOOD",
                url: "https://www.ifood.com.co/delivery/bogota-bog/erepa-santa-rita/0e7a7e25-7cd5-4c2f-9f6b-df0340369c8d",
              },
              {
                description: "UBER_EATS",
                url: "https://www.ubereats.com/co/bogota/food-delivery/erepa-parrilla/2Z_9uC3GSACZRrsH_ruLXg",
              },
            ])}
          </div>
        </div>
      </div>
    );
  }

  boxIcon(description, productPrincipal) {
    return (
      <div
        className={cx("box-icon", "margin", productPrincipal && "principal")}
      >
        <div className={cx("img-icon")}>
          {description.map((element, index) => (
            <p
              key={`text-${index}`}
              onClick={() => {
                element.url && window.open(element.url, "_blank");
              }}
            >
              {element.description}
            </p>
          ))}
        </div>
      </div>
    );
  }
}

export default RequestFood;
