import React, { Component } from "react";
// import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import classNames from "classnames/bind";
import style from "./google_maps.module.scss";
const cx = classNames.bind(style);

class GoogleMaps extends Component {
  state = {
    ubicacionActual: null,
  };

  componentDidMount = () => {
    // this.setState({
    //   ubicacionActual: new window.google.maps.LatLng(4.5976809, -74.1250763),
    // });
  };
  render() {
    return (
      <>
        <div className={cx("line-separator")}></div>
        {/* <Map
          google={this.props.google}
          onReady={this.fetchPlaces}
          className={"map googleMaps"}
          center={this.state.ubicacionActual}
          zoom={19}
          containerStyle={{
            position: "absolute",
            width: "100%",
            height: "80%",
          }}
        >
          <Marker
            title={`Ubicación:Erepa`}
            name={"Erepa"}
            position={{ lat: 4.5976809, lng: -74.1250763 }}
            onClick={() => {
              window.open(
                "https://www.google.com/maps/place/Erepa/@4.5976809,-74.127265,17z/data=!3m1!4b1!4m5!3m4!1s0x8e3f9ed2a3934da5:0x34947c96b1e9c0ae!8m2!3d4.5976809!4d-74.1250763",
                "_blank"
              );
            }}
          />
        </Map> */}
      </>
    );
  }

  // fetchPlaces(mapProps, map) {
  //   const { google } = mapProps;
  //   new google.maps.places.PlacesService(map);
  // }
}

export default GoogleMaps;

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyCZQdWZWsNyakL30EbvVherjO4c9HcqFc8",
// })(GoogleMaps);
