const shoppingReducer = (state, action) => {
  switch (action.type) {
    case 'PRODUCT':
      return {
        ...state,
        products: action.payload.products,
        totalShopping: action.payload.totalShopping,
        totalCountProducts: state.totalCountProducts + 1
      };
    case 'PRODUCT_PROMOTION':
      return {
        ...state,
        products: action.payload.products,
        totalShopping: action.payload.totalShopping,
        totalCountProducts: state.totalCountProducts + 1
      };
    case 'DELETE':
      return {
        ...state,
        products: action.payload.products,
        totalShopping: state.totalShopping - action.payload.subtractProduct,
        totalCountProducts: state.totalCountProducts - 1
      }; 
    case 'DELETE_PROMOTION':
      return {
        ...state,
        promotions: action.payload,
        totalShopping: state.totalShopping - 1,
        totalCountProducts: state.totalCountProducts - 1
      };
    default:
      return state;
  }
}

export default shoppingReducer;