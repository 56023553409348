import React, { forwardRef } from "react";
import style from "./form_input_component.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

const FormInput = ({ type, onChange, onBlur, name, placeholder, error }, ref) => {
  switch (type) {
    case "select":
      return (
        <>
          <select
            ref={ref}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            className={cx(
              "input-text",
              error?.type === "required" ? "error-input" : ""
            )}
          >
            <option value="">Medio de pago</option>
            <option value="Nequi">Nequi</option>
            <option value="Tarjeta Debito">Tarjeta Debito</option>
            <option value="Tarjeta Credito">Tarjeta Credito</option>
            <option value="Efectivo">Efectivo</option>
            <option value="Leal Coins">Leal Coins</option>
          </select>
        </>
      );
    default:
      return (
        <>
          <input
            ref={ref}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            className={cx(
              "input-text",
              error?.type === "required" ? "error-input" : ""
            )}
            autoComplete="false"
          />
        </>
      );
  }
}

export default forwardRef(FormInput);
