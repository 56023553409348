import React, { Component } from 'react';
import ContentTabs from './components/content_tabs_component';
import style from './tabs.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(style);

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelectedKey: 0,
    };
  }

  _tab(key, text) {
    return (
      <div
        key={`tab-${key}`}
        className={cx(
          'tab',
          key === this.state.tabSelectedKey ? 'selected' : ''
        )}
        onClick={() => {
          this.setState({ tabSelectedKey: key });
        }}
      >
        {text[0].toUpperCase() + text.slice(1)}
      </div>
    );
  }

  render() {
    const { tabsContent, onSelectedItem } = this.props;
    const { categorias: tabs } = tabsContent;
    return (
      <div className={cx('container')}>
        <div className={cx('content-tab')}>
          {tabs.map((item, key) => this._tab(key, item.descripcion))}
        </div>
        <ContentTabs
          tabsAndProducts={tabs}
          tabActive={this.state.tabSelectedKey}
          onSelectedItem={onSelectedItem}
        />
      </div>
    );
  }
}

export default Tabs;
