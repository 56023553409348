import React, { Component } from "react";
import classNames from "classnames/bind";
import style from "./products.module.scss";
import { verMas } from "../../../../assets/images";
// import menuErepaPdf from "../../../../assets/docs/menu-erepa.pdf";
import { Link } from "react-router-dom";

const cx = classNames.bind(style);

class Products extends Component {
  render() {
    return (
      <div name={"principal-products"}>
        {/* {alert(
          "La resolución de pantalla que tienes en este momento es de: " +
            window.screen.width +
            " x " +
            window.screen.height
        )} */}
        <div className={cx("line-separator")}></div>
        <div className={cx("content")}>
          <div className={cx("fondo")}></div>
          <div className={cx("container")}>
            {this.lateralCard("Arepas")}
            {this.lateralCard("Hamburguesas")}
            {this.lateralCard("Especiales")}
            {this.lateralCard("Bebidas")}
            {this.lateralCard("Congelados")}
          </div>
        </div>
        {/* <div className={cx("content")}>
          <div className={cx("fondo")}></div>
          <div className={cx("container")}>
            {this.lateralCard(1, "Arepas")}
            <div className={cx("card-central")}>
              {this.centerCard(1, "Hamburguesas")}
              {this.centerCard(2, "Especiales")}
            </div>
            {this.lateralCard(2, "Bebidas")}
          </div>
        </div> */}
        <div className={cx("line-separator")}></div>
      </div>
    );
  }

  lateralCard(descriptionProduct) {
    return (
      <div
        className={cx(
          "card-lateral",
          `product-img-${descriptionProduct.toLowerCase()}`
        )}
      >
        {this.inkWell(descriptionProduct)}
      </div>
    );
  }

  centerCard(descriptionProduct) {
    return (
      <div
        className={cx(
          "card-product-central",
          `product-img-${descriptionProduct.toLowerCase()}`
        )}
      >
        {this.inkWell(descriptionProduct)}
      </div>
    );
  }

  inkWell(descriptionProduct) {
    return (
      <div className={cx("box")}>
        <div className={cx("product-first")}>
          <Link
            to=""
            onClick={() =>
              (window.location.href = "https://erepa.getjusto.com/pedir")
            }
          >
            <img className={cx("img-icon")} src={verMas} alt="Ver más" />
          </Link>
          <span className={cx("text-description-product")}>
            {descriptionProduct}
          </span>
        </div>
      </div>
    );
  }
}

export default Products;
