import React from "react";
import PropTypes from "prop-types";
import style from "./button_back.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(style);

function ButtonBack({ onClick, label }) {
  return (
    <div className={cx("button")} onClick={onClick}>
      <span>{label}</span>
    </div>
  );
}

ButtonBack.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

ButtonBack.defaultProps = {
  onClick: () => { },
  label: "Cerrar",
};

export default ButtonBack;
