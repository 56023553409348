import React from "react";
import { sumIcon } from "../../../assets/images";
import style from "./button_add.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(style);

function ButtonAdd({ text, onClick }) {
  return (
    <button
      type="button"
      className={cx("button")}
      style={{ outline: "none" }}
      onClick={onClick}
    >
      <span>{text}</span>
      <img src={sumIcon} alt="Más" />
    </button>
  );
}

export default ButtonAdd;
