import React from "react";
import classNames from "classnames/bind";
import style from "./button_shopping.module.scss";
import Badge from "../../badge/badge_component";
import { shoppingCart } from "../../../assets/images";
const cx = classNames.bind(style);

function ButtonShopping({ counterBadge, onClick }) {
  return (
    <div className={cx("button-shopping")} onClick={onClick}>
      <img src={shoppingCart} width="30" alt="shopping-car" />
      <Badge value={counterBadge} top={"-2px"} right={"-4px"} />
    </div>
  );
}

export default ButtonShopping;
