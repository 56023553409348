// Image PNG
export const logoBrown = require("./corporateImage/png/logoCafe.png");
export const logoErepaBrown = require("./corporateImage/png/logoErepaCafe.png");
export const logoErepaWhite = require("./corporateImage/png/logoErepaBlanco.png");
export const verMas = require("./general/png/erepa_ver_mas.png");
export const tiendas = require("./general/png/Tiendas.png");
export const descargaMenu = require("./general/png/DescargaMenu.png");
export const enLine = require("./general/png/EnLinea.png");
export const botonErepa = require("./general/png/boton_erepa.png");
export const sumIcon = require("./general/png/sum-icon.png");
export const shoppingCart = require("./general/png/shopping-cart.png")
export const close = require("./general/png/close.png")
export const remove = require("./general/png/eliminar.png")
export const lineClose = require("./general/png/minus-sign.png")

export const products = {
  entradas: {
    promotions: [],
    products: {
      pibil: require("./products/menu/entradas/pibil.png"),
      pinaquesuda: require("./products/menu/entradas/pina-quesuda.png"),
      cheddar: require("./products/menu/entradas/cheddar.png"),
      rellenassabor: require("./products/menu/entradas/rellenas-sabor.png"),
      bravas: require("./products/menu/entradas/bravas.png"),
      puebla: require("./products/menu/entradas/chorizopuebla.png"),
    },
  },
  menuinfantil: {
    promotions: [],
    products: {
      nuggets: require("./products/menu/infantil/nuggets.png"),
      salchipapa: require("./products/menu/product-default.png"),
      minihamburguesa: require("./products/menu/infantil/minihamburguesa.png"),
    },
  },
  arepas: {
    promotions: [],
    products: {
      tipica: require("./products/menu/product-default.png"),
      queso: require("./products/menu/product-default.png"),
      cochinapibil: require("./products/menu/arepas/cochina-pibil.png"),
      mitierra: require("./products/menu/arepas/mi-tierra.png"),
      pollobacon: require("./products/menu/arepas/pollo-bacon.png"),
      florentina: require("./products/menu/arepas/florentina.png"),
      polloparisina: require("./products/menu/arepas/pollo-parisina.png"),
      nido: require("./products/menu/arepas/nido.png"),
      michoacana: require("./products/menu/arepas/michoacana.png"),
    },
  },
  hamburguesas: {
    promotions: [],
    products: {
      clasica: require("./products/menu/product-default.png"),
      bacon: require("./products/menu/product-default.png"),
      parisina: require("./products/menu/hamburguesas/parisina.png"),
      erepa: require("./products/menu/hamburguesas/erepa.png"),
      perolera: require("./products/menu/hamburguesas/perolera.png"),
      gaucha: require("./products/menu/hamburguesas/gaucha.png"),
    },
  },
  perros: {
    promotions: [],
    products: {
      clasico: require("./products/menu/perros/clasico.png"),
      tocino: require("./products/menu/product-default.png"),
      perolero: require("./products/menu/product-default.png"),
    },
  },
  especiales: {
    promotions: [],
    products: {
      salteadomixto: require("./products/menu/especiales/salteadomixto.png"),
      salteadotrescarnes: require("./products/menu/especiales/salteadomixto.png"),
      costillas: require("./products/menu/especiales/costillas.png"),
      alitas: require("./products/menu/especiales/alitas.png"),
      beefancho: require("./products/menu/especiales/beefancho.png"),
      pechugaflorentina: require("./products/menu/especiales/pechugaflorentina.png"),
      filetepechuga: require("./products/menu/especiales/filtepechuga.png"),
      picada: require("./products/menu/product-default.png")
    },
  },
  congelados: {
    promotions: [],
    products: {
      arepapeto: require("./products/menu/congelados/arepas-maiz-peto.png"),
      croquetas: require("./products/menu/congelados/croquetas-arepas.png"),
      costillascerdo: require("./products/menu/congelados/costillas-cerdo.png"),
      carnecerdo: require("./products/menu/congelados/cerdo-desmechado.png"),
      carneres: require("./products/menu/congelados/res-desmechada.png"),
      carnehamburguesa: require("./products/menu/congelados/carne-hamburguesa.png"),
    },
  },
  ensaladas: {
    promotions: [],
    products: {
      mediterranea: require("./products/menu/ensaladas/mediterranea.png"),
      taco: require("./products/menu/ensaladas/taco.png"),
    },
  },
  bebidas: {
    promotions: [],
    products: {
      jugonatural: require("./products/menu/product-default.png"),
    },
  },
  postres: {
    promotions: [],
    products: {
      postre: require("./products/menu/product-default.png"),
      fusionmitierra: require("./products/menu/postres/fusionmitierra.png"),
    },
  },
  acompañamientos: {
    promotions: [],
    products: {
      acompanamiento: require("./products/menu/product-default.png"),
    },
  },
  adiciones: {
    promotions: [],
    products: {
      acompanamiento: require("./products/menu/product-default.png"),
    },
  },
};
//Image JPG
export const construction = require("./general/jpg/webConstruction.jpg");
export const carouselImg1 = require("./carousel/jpg/Erepa1-1920x950.jpg");
export const carouselImg2 = require("./carousel/jpg/Erepa2-1920x950.jpg");
export const carouselImg3 = require("./carousel/jpg/Erepa3-1920x950.jpg");
export const carouselImg4 = require("./carousel/jpg/Erepa4-1920x950.jpg");
export const carouselImg5 = require("./carousel/jpg/Erepa5-1920x950.jpg");
export const carouselImg6 = require("./carousel/jpg/Erepa6-1920x950.jpg");
export const promociones1 = require("./carousel/jpg/PromocionesMadres.jpg");
export const promociones2 = require("./carousel/jpg/PromocionesPadres.jpg");

export const productPay = require("./general/jpg/hamburguer.jpg");
export const jugos = require("./general/jpg/jugos.jpg");
export const entradaRestaurante = require("./general/jpg/entrada-restaurante.jpg");
export const restaurante = require("./general/jpg/restaurante.jpg");
//Image SVG
export const star = require("./general/svg/star.svg");
export const ubication = require("./general/svg/ubicacion.svg");
export const phone = require("./general/svg/telefono.svg");
export const reloj = require("./general/svg/reloj.svg");
export const email = require("./general/svg/email.svg");
