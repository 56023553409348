import React from "react";
import { Route, Switch } from "react-router-dom";
import { Navbar } from "../components";
import { PrincipalPage, Ecommerce } from "../views";

function Erepa() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={PrincipalPage} />
        <Route exact path="/menu/:id" component={PrincipalPage} />
        <Route exact path="/products" component={Ecommerce} />
        <Route component={PrincipalPage} />
      </Switch>
    </>
  );
}

export default Erepa;
