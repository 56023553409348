import React from "react";
import PropTypes from "prop-types";
import style from "./button_principal.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(style);

function ButtonPrincipal({ disabled, onClick, text }) {
  return (
    <button
      type="submit"
      className={cx("button", "principal", disabled && "disabled")}
      onClick={onClick}
    >
      <span>{text}</span>
    </button>
  );
}

ButtonPrincipal.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

ButtonPrincipal.defaultProps = {
  disabled: false,
  onClick: () => {},
  text: "",
};

export default ButtonPrincipal;
