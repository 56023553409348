import React from 'react';
import { ButtonAdd } from 'components';
import { products as imgs } from 'assets/images';
import { formatterPeso } from 'helpers';

import style from './content_tabs.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

function ContentTabs({ tabsAndProducts, tabActive, onSelectedItem }) {
  const onClickButtonAdd = (tab, product, detailProduct, combo) => {
    const payload = {
      category: tab,
      productSelected: product,
      ingredients: detailProduct,
      optionsCombo: combo,
    };
    onSelectedItem(payload);
  };

  const products = (payloadTab) => {
    const {
      productos: products,
      descripcion: tab,
      detalleProducto: detailProduct,
      combo,
    } = payloadTab;
    return (
      products && (
        <div className={cx('content-items')}>
          {products.map((item, key) => (
            <div key={key} className={cx('card-item')}>
              <div className={cx('product')}>
                <img
                  src={
                    imgs[tab.toLowerCase().trim().replace(' ', '')]['products'][
                      item.imagen
                    ]
                  }
                  alt='Producto'
                />
              </div>
              <div className={cx('description')}>
                <h5>{item.nombre}</h5>
                <p>
                  {item.descripcion.length >= 60
                    ? item.descripcion
                        .substr(0, 60)
                        .replace(/\W*\s(\S)*$/, '...')
                    : item.descripcion}
                </p>
                <div className={cx(`button`)}>
                  <ButtonAdd
                    text={formatterPeso.format(item.precio)}
                    onClick={() =>
                      onClickButtonAdd(tab, item, detailProduct, combo)
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div className={cx('container')}>
      {tabsAndProducts.map((tab, key) => {
        let active = key === tabActive && 'active';
        return (
          <div key={`container-${key}`} className={cx('content-tabs', active)}>
            {active && products(tab)}
          </div>
        );
      })}
    </div>
  );
}

export default ContentTabs;
