import React from "react";
import { formatterPeso } from "helpers";
import ButtonAdd from "../buttons/button_add/button_add_component";
import { products as imgs } from "assets/images";
import classNames from "classnames/bind";
import style from "./card_product.module.scss";

const cx = classNames.bind(style);

function CardProduct({ category, index, item, handleClick }) {
  return (
    <div className={cx("card")}>
      <div className={cx("product")}>
        {/* <img src={imgs[category]["promotions"][index]} alt={item.name} /> */}
        <img
          src={
            imgs[category.toLowerCase().trim().replace(" ", "")]["products"][
              item.imagen
            ]
          }
          alt="Producto"
        />
      </div>
      <div className={cx("description")}>
        <div className={cx("card-promotion")}>Ver más</div>
        <h5>{item.nombre}</h5>
        {/* <h6>Ver más</h6> */}
        <ButtonAdd
          text={formatterPeso.format(item.precio)}
          onClick={handleClick}
        />
      </div>
    </div>
  );
}

export default CardProduct;
