import React from 'react';
import { useForm } from 'react-hook-form';
import { capitalizeFirstLetter, formatterPeso } from 'helpers';
import { Modal, FormInput, ButtonPrincipal, ButtonBack } from 'components';
import { registerUserLvl } from 'redux/reducers/user/actions';
import { useStateValue } from 'redux/store';

const ModalContactUser = ({ showModal, closeModal }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onBlur' });
  const [{ products, shopping }, dispatch] = useStateValue();

  const buildMessage = (formUser) => {
    let lineBreak = '%0A';

    let lineWA = `https://wa.me/573212516302?text=¡Hola! hablas con *${formUser.firstName.replace(
      '#',
      ''
    )}.*${lineBreak}${lineBreak}`;
    lineWA += `Estos son mis datos:${lineBreak}- *Teléfono*: ${formUser.phone.replace(
      '#',
      ''
    )}${lineBreak}- *Dirección*: ${formUser.address.replace(
      '#',
      'No.'
    )} ${formUser.aditionalAddress.replace('#', '')}
    ${lineBreak}- *Barrio*: ${formUser.neighborhood}
    ${lineBreak}- *Medio de pago:* ${formUser.paymentMethod}${lineBreak}
    `;

    lineWA += `${lineBreak}*Quisiera hacer un pedido* de los siguientes productos:${lineBreak}`;

    products.categorias.forEach((category) => {
      let normalProducts = shopping.products.filter(
        (item) => item.categoria === category.descripcion
      );
      if (normalProducts.length) {
        lineWA += `${lineBreak}*${capitalizeFirstLetter(
          category.descripcion
        )}*${lineBreak}${lineBreak}`;
        lineWA += `--------------------------------${lineBreak}`;
        normalProducts.forEach((product) => {
          var totalPriceProduct = product.precio + (product.combo || 0);
          product.ingredientes.forEach((price) => {
            totalPriceProduct += price.value;
          });

          lineWA += `*${product.count}* ${product.nombre}${
            product.combo ? ' en combo' : ''
          } *${formatterPeso.format(totalPriceProduct)}* ${lineBreak}`;

          let ingredientes = '';
          let adicionales = '';

          product.ingredientes.forEach((item) => {
            if (item.value === 0) {
              ingredientes += `- ${item.titulo}: ${
                item.descripcion + lineBreak
              }`;
            } else if (item.value > 0) {
              adicionales += `- ${item.titulo}: ${item.descripcion} ${
                formatterPeso.format(item.value) + lineBreak
              }`;
            }
          });
          if (ingredientes !== '') {
            lineWA += `*Ingredientes* ${lineBreak}`;
            lineWA += ingredientes;
          }

          if (adicionales !== '') {
            lineWA += `*Adicionales* ${lineBreak}`;
            lineWA += adicionales;
          }

          lineWA += `- - - - - - - - - - - - - - - - - - - - -${lineBreak}`;
        });
      }
    });
    return (lineWA += `${lineBreak}*Total:* ${formatterPeso.format(
      shopping?.totalShopping
    )}`);
  };

  const onSubmit = (formUser) => {
    const resultWA = buildMessage(formUser);
    dispatch(registerUserLvl(formUser));
    window.open(resultWA, '_blank');
    closeModal();
  };

  return (
    <Modal showModal={showModal} showFooter={false}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: '0px 20px',
        }}
      >
        <div style={{ marginBottom: '10px' }}>
          Introduce tus <b>datos</b> para realizar el <b>pedido</b>
        </div>
        <div style={{ flexGrow: 1 }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <FormInput
                {...register('firstName', { required: true, maxLength: 100 })}
                type='text'
                placeholder='Nombre completo'
                error={errors.firstName}
              />
              <FormInput
                {...register('phone', { required: true, maxLength: 20 })}
                type='phone'
                placeholder='Celular'
                error={errors.phone}
              />
              <FormInput
                {...register('address', { required: true })}
                type='text'
                placeholder='Dirección de residencia'
                error={errors.address}
              />
              <FormInput
                {...register('aditionalAddress', { required: true })}
                type='text'
                placeholder='Info. Adicional: Casa, Torre, Piso...'
                error={errors.aditionalAddress}
              />
              <FormInput
                {...register('neighborhood', { required: true })}
                type='text'
                placeholder='Barrio'
                error={errors.neighborhood}
              />
              <FormInput
                {...register('paymentMethod', { required: true })}
                type='select'
                error={errors.paymentMethod}
              />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ButtonBack label={'Atras'} onClick={() => closeModal(true)} />
              <ButtonPrincipal text={'Siguiente'} />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ModalContactUser;
