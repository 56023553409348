import React from 'react';
import Erepa from './views/erepa';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from './redux/store';
import { reducers } from './redux/reducers';
import initialState from './initialState';
import { PageConstruction } from './components';

function App() {
  const disabledPage = false;
  return (
    <Provider reducer={reducers} initialState={initialState}>
      {disabledPage ? (
        <PageConstruction />
      ) : (
        <Router>
          <Erepa />
        </Router>
      )}
    </Provider>
  );
}

export default App;
