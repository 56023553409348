import React, { Component } from "react";
import classNames from "classnames/bind";
import style from "./line_separator.module.scss";
const cx = classNames.bind(style);

class LineSeparator extends Component {
  render() {
    return <div className={cx("content")}></div>;
  }
}

export default LineSeparator;
