import productsReducer from './products/reducer';
import shoppingReducer from "./shopping/reducer";
import userReducer from "./user/reducer";

export const reducers = ({
  products,
  shopping,
  user
}, action) => ({
  products: productsReducer(products, action),
  shopping: shoppingReducer(shopping, action),
  user: userReducer(user, action)
});
